import React, { lazy, Suspense } from "react";
import './App.css';

import { Route, Switch, Router } from "react-router-dom"

const MainSlider = lazy(() => import('./HomeSlider/MainSlider'));

const AboutClg = lazy(() => import("./About/AboutClg"));
const DeanAcademics = lazy(() => import("./About/dean-academics"));
const DeanAdmissions = lazy(() => import("./About/dean-admissions"));
const BOS = lazy(() => import("./About/board-of-studies"));
const Management = lazy(() => import("./About/Management"));
const SWECRecognitions = lazy(() => import("./About/SWECRecognitions"));
const Aicte = lazy(() => import("./About/Aicte"));
const GoverningBody = lazy(() => import("./About/GoverningBody"));
const Prncipal = lazy(() => import("./About/Principal"));

const Disclosure = lazy(() => import("./About/Disclosure"));
const ServiceRules = lazy(() => import("./About/ServiceRules"));
const Organogram = lazy(() => import("./About/Organogram"));
const SalientFeatures = lazy(() => import("./About/SalientFeatures"));
const CSE = lazy(() => import("./Departments/mainTags/CSE"));
const CseDs = lazy(() => import("./Departments/mainTags/CseDs"));
const CseAIMl = lazy(() => import("./Departments/mainTags/CseAIMl"));
const DECE = lazy(() => import("./Departments/mainTags/D-ECE"));
const IT = lazy(() => import("./Departments/mainTags/IT"));
const EEE = lazy(() => import("./Departments/mainTags/EEE"));
const MBA = lazy(() => import("./Departments/mainTags/MBA"));
const SandH = lazy(() => import("./Departments/mainTags/SandH"));
const DPE = lazy(() => import("./Departments/mainTags/DPE"));
const Contact = lazy(() => import("./contact/Contact"));
const InnovationIncubation = lazy(() => import("./RandD/InnovationIncubation"));
const GrantsReceived = lazy(() => import("./RandD/GrantsReceived"));
const ResearchGuide = lazy(() => import("./RandD/ResearchGuide"));
const IndustryInstituteInteraction = lazy(() => import("./RandD/IndustryInstituteInteraction"));
const QualityPolicy = lazy(() => import("./Iqac/QualityPolicy"));
const QualityEducation = lazy(() => import("./Iqac/QualityEducation"));
const IQAC = lazy(() => import("./Iqac/IQAC"));
const NIRF = lazy(() => import("./Nirf/NIRF"));
const Overall = lazy(() => import("./Nirf/Overall"));
const Innovation = lazy(() => import("./Nirf/Innovation"));
const Feedback = lazy(() => import("./feedback/Feedback"));
const Testimonials = lazy(() => import("./testimonials/Testimonials"));
const GiveranceCell = lazy(() => import("./SeconNav/GiveranceCell"));
const Alumni = lazy(() => import("./SeconNav/Alumni"));
const ProfessionalSocieties = lazy(() => import("./SeconNav/ProfessionalSocieties"));
const EDC = lazy(() => import("./SeconNav/EDC"));
const CentralFacilities = lazy(() => import("./Facilities/CentralFacilities"));
const Transport = lazy(() => import("./Facilities/Transport"));
const ComputerCenter = lazy(() => import("./Facilities/ComputerCenter"));
const Hostel = lazy(() => import("./Facilities/Hostel"));
const ICT = lazy(() => import("./Facilities/ICT"));
const OBE = lazy(() => import("./Activities/OBE"));
const NCC = lazy(() => import("./Activities/NCC"));
const NSS = lazy(() => import("./Activities/NSS"));
const SSWES = lazy(() => import("./Activities/SSWES"));
const ClgMagzine = lazy(() => import("./Activities/ClgMagzine"));
const GreenCampus = lazy(() => import("./Activities/GreenCampus"));
const ITFinishingSchool = lazy(() => import("./Activities/ITFinishingSchool"));
const ResultUpload = lazy(() => import("./ExaminationCell/Results-ofB.Tech-2018-22-batch-and-MBA-2020-22-batch"));
const Admissions = lazy(() => import("./About/Admissions"));
const TPD = lazy(() => import("./TrainingAndPlacements/TPD"));
const P2021 = lazy(() => import('./TrainingAndPlacements/P2021'));
const Library = lazy(() => import("./Departments/mainTags/Library"));
const Jntuh = lazy(() => import("./ExaminationCell/Jntuh"));
const AcaMba = lazy(() => import("./ExaminationCell/AcaMba"));
const JntuSyllubus = lazy(() => import("./ExaminationCell/JntuSyllubus"));
const JntuTimeTable = lazy(() => import("./ExaminationCell/JntuTimeTable"));
const Notifications = lazy(() => import("./ExaminationCell/Notifications"));
const Academics = lazy(() => import("./ExaminationCell/Academics"));
const FeedbackForms = lazy(() => import("./ExaminationCell/FeedbackForms"));
const HolidaysList = lazy(() => import("./ExaminationCell/HolidaysList"));
const AcademicAutonomous = lazy(() => import("./ExaminationCell/Autonomous/AcademicAutonomous"));
const AutonomousSyllubus = lazy(() => import("./ExaminationCell/Autonomous/AutonomousSyllubus"));
const P1920 = lazy(() => import("./TrainingAndPlacements/P1920"));
const P1819 = lazy(() => import("./TrainingAndPlacements/P1819"));
const P1718 = lazy(() => import("./TrainingAndPlacements/P1718"));
const P1617 = lazy(() => import("./TrainingAndPlacements/P1617"));
const P1516 = lazy(() => import("./TrainingAndPlacements/P1516"));
const P1415 = lazy(() => import("./TrainingAndPlacements/P1415"));
const P1314 = lazy(() => import("./TrainingAndPlacements/P1314"));
const P1213 = lazy(() => import("./TrainingAndPlacements/P1213"));
const TechnicalTraining = lazy(() => import("./TrainingAndPlacements/TechnicalTraining"));
const Task = lazy(() => import("./TrainingAndPlacements/Task"));
const DeptWise = lazy(() => import("./TrainingAndPlacements/DeptWise"));
const CseVision = lazy(() => import("./Departments/cseDepartmentLinks/CseVision"));
const CSEpo = lazy(() => import("./Departments/cseDepartmentLinks/CSEpo"));
const CSEHod = lazy(() => import("./Departments/cseDepartmentLinks/CSEHod"));
const CSEStudentProjects = lazy(() => import('./Departments/cseDepartmentLinks/CSEStudentProjects'));
const IndustryVisits = lazy(() => import('./Departments/cseDepartmentLinks/CSEIndustryVisits'));
const CSEStudentDPrograms = lazy(() => import("./Departments/cseDepartmentLinks/CSEStudentDPrograms"));
const CSESyllubus = lazy(() => import("./Departments/cseDepartmentLinks/CSESyllubus"));
const CSEPlacements = lazy(() => import("./Departments/cseDepartmentLinks/CSEPlacements"));
const CSEProffessional = lazy(() => import("./Departments/cseDepartmentLinks/CSEProffessional"));
const CSENewsLetter = lazy(() => import("./Departments/cseDepartmentLinks/CSENewsLetter"));
const CSEFacultyPublications = lazy(() => import("./Departments/cseDepartmentLinks/CSEFacultyPublications"));
const FacultyInnovation = lazy(() => import("./Departments/cseDepartmentLinks/FacultyInnovation"));
const CSEWorkShop = lazy(() => import("./Departments/cseDepartmentLinks/CSEWorkShop"));
const CSEbooksPublished = lazy(() => import("./Departments/cseDepartmentLinks/CSEbooksPublished"));
const PatentPublications = lazy(() => import("./Departments/cseDepartmentLinks/PatentPublications"));
const CSELaboratoryProjects = lazy(() => import("./Departments/cseDepartmentLinks/CSELaboratoryProjects"));
const CSEAcademicSponser = lazy(() => import("./Departments/cseDepartmentLinks/CSEAcademicSponser"));
const CSEBridgeCourse = lazy(() => import("./Departments/cseDepartmentLinks/CSEBridgeCourse"));
const CSECourseFiles = lazy(() => import("./Departments/cseDepartmentLinks/CSECourseFiles"));
const CSELaboratories = lazy(() => import("./Departments/cseDepartmentLinks/CSELaboratories"));
const CSELibrary = lazy(() => import("./Departments/cseDepartmentLinks/CSELibrary"));
const CSEMous = lazy(() => import("./Departments/cseDepartmentLinks/CSEMous"));
const CSEDS = lazy(() => import("./Departments/cseDepartmentLinks/CSEDS"));
const EceVision = lazy(() => import("./Departments/ECELinks/EceVision"));
const EcePo = lazy(() => import("./Departments/ECELinks/EcePo"));
const EceHod = lazy(() => import("./Departments/ECELinks/EceHod"));
const EceFaculty = lazy(() => import("./Departments/ECELinks/EceFaculty"));
const EceCalender = lazy(() => import("./Departments/cseDepartmentLinks/EceCalender"));
const EceStudentProjects = lazy(() => import("./Departments/ECELinks/EceStudentProjects"));
const EceIndustryIntern = lazy(() => import("./Departments/ECELinks/EceIndustryIntern"));
const EceIndustryVisits = lazy(() => import("./Departments/ECELinks/EceIndustryVisits"));
const EceSDP = lazy(() => import("./Departments/ECELinks/EceSDP"));
const EceSyllabus = lazy(() => import("./Departments/ECELinks/EceSyllabus"));
const EceAnalysis = lazy(() => import("./Departments/ECELinks/EceAnalysis"));
const EcePlacements = lazy(() => import("./Departments/ECELinks/EcePlacements"));
const EceProffessionalBodies = lazy(() => import("./Departments/ECELinks/EceProffessionalBodies"));
const EceMagazines = lazy(() => import("./Departments/ECELinks/EceMagazines"));
const EceStudentPublications = lazy(() => import("./Departments/ECELinks/EceStudentPublications"));
const ECEFacultyInnovations = lazy(() => import("./Departments/ECELinks/ECEFacultyInnovations"));
const ECEworkshops = lazy(() => import("./Departments/ECELinks/ECEworkshops"));
const EceFacultyPerprmance = lazy(() => import("./Departments/ECELinks/EceFacultyPerprmance"));
const EceLaboratoryProjects = lazy(() => import("./Departments/ECELinks/EceLaboratoryProjects"));
const EceAcademicSponsered = lazy(() => import("./Departments/ECELinks/EceAcademicSponsered"));
const EceCourseFiles = lazy(() => import("./Departments/ECELinks/EceCourseFiles"));
const EceDepartmentLibrary = lazy(() => import("./Departments/ECELinks/EceDepartmentLibrary"));
const EceMous = lazy(() => import("./Departments/ECELinks/EceMous"));
const ItVision = lazy(() => import("./Departments/ITlinks/ItVision"));
const ItPO = lazy(() => import("./Departments/ITlinks/ItPO"));
const ITHOD = lazy(() => import("./Departments/ITlinks/ITHOD"));
const ITSprojects = lazy(() => import("./Departments/ITlinks/ITSprojects"));
const ITindustryIntern = lazy(() => import("./Departments/ITlinks/ITindustryIntern"));
const ItIndustryVisits = lazy(() => import("./Departments/ITlinks/ItIndustryVisits"));
const ItSyllabus = lazy(() => import("./Departments/ITlinks/ItSyllabus"));
const ItPlacements = lazy(() => import("./Departments/ITlinks/ItPlacements"));
const Itmagazines = lazy(() => import("./Departments/ITlinks/Itmagazines"));
const ItFacultyAchievements = lazy(() => import("./Departments/ITlinks/ItFacultyAchievements"));
const ItFaxultyInnovations = lazy(() => import("./Departments/ITlinks/ItFaxultyInnovations"));
const ItWorkshops = lazy(() => import("./Departments/ITlinks/ItWorkshops"));
const ItBridgeCourse = lazy(() => import("./Departments/ITlinks/ItBridgeCourse"));
const ItLibrary = lazy(() => import("./Departments/ITlinks/ItLibrary"));
const EEEVision = lazy(() => import("./Departments/EEELinks/EEEVision"));
const EEEPoAnsPSO = lazy(() => import("./Departments/EEELinks/EEEPoAnsPSO"));
const EEEIndustryInterm = lazy(() => import("./Departments/EEELinks/EEEIndustryInterm"));
const EEEIndustryVisits = lazy(() => import("./Departments/EEELinks/EEEIndustryVisits"));
const EEESDP = lazy(() => import("./Departments/EEELinks/EEESDP"));
const EEESyllabus = lazy(() => import("./Departments/EEELinks/EEESyllabus"));
const EEEPlacements = lazy(() => import("./Departments/EEELinks/EEEPlacements"));
const EEEProfessionalBodies = lazy(() => import("./Departments/EEELinks/EEEProfessionalBodies"));
const EEEMagazines = lazy(() => import("./Departments/EEELinks/EEEMagazines"));
const EEEStudentAchievements = lazy(() => import("./Departments/EEELinks/EEEStudentAchievements"));
const EEEFacultyInnovation = lazy(() => import("./Departments/EEELinks/EEEFacultyInnovation"));
const EEEworkshops = lazy(() => import("./Departments/EEELinks/EEEworkshops"));
const EEEFacultyPerformance = lazy(() => import("./Departments/EEELinks/EEEFacultyPerformance"));
const EEEEandD = lazy(() => import("./Departments/EEELinks/EEEEandD"));
const EEEAcademicSponser = lazy(() => import("./Departments/EEELinks/EEEAcademicSponser"));
const EEECourseFiles = lazy(() => import("./Departments/EEELinks/EEECourseFiles"));
const EEELibrary = lazy(() => import("./Departments/EEELinks/EEELibrary"));
const EEEMous = lazy(() => import("./Departments/EEELinks/EEEMous"));
const EEEAcademicPlan = lazy(() => import("./Departments/EEELinks/EEEAcademicPlan"));
const MbaVision = lazy(() => import("./Departments/mbaLinks/MbaVision"));
const MbaPoandPso = lazy(() => import("./Departments/mbaLinks/MbaPoandPso"));
const MbaHod = lazy(() => import("./Departments/mbaLinks/MbaHod"));
const MbaFaculty = lazy(() => import("./Departments/mbaLinks/MbaFaculty"));
const MbaMentorList = lazy(() => import("./Departments/mbaLinks/MbaMentorList"));
const MbaInfrastructure = lazy(() => import("./Departments/mbaLinks/MbaInfrastructure"));
const MbaSyllabus = lazy(() => import("./Departments/mbaLinks/MbaSyllabus"));
const MbaRemedialClass = lazy(() => import("./Departments/mbaLinks/MbaRemedialClass"));
const MbaBridgeCourse = lazy(() => import("./Departments/mbaLinks/MbaBridgeCourse"));
const MbaWorkShop = lazy(() => import("./Departments/mbaLinks/MbaWorkShop"));
const MbaActivities = lazy(() => import("./Departments/mbaLinks/MbaActivities"));
const MbaInnovativeTeachig = lazy(() => import("./Departments/mbaLinks/MbaInnovativeTeachig"));
const MbaPlacements = lazy(() => import("./Departments/mbaLinks/MbaPlacements"));
const Svision = lazy(() => import("./Departments/SandH/Svision"));
const SHod = lazy(() => import("./Departments/SandH/SHod"));
const SandHFaculty = lazy(() => import("./Departments/SandH/SandHFaculty"));
const Hactivities = lazy(() => import("./Departments/SandH/Hactivities"));
const DpeHod = lazy(() => import("./Departments/DPE/DpeHod"));
const DpeInfra = lazy(() => import("./Departments/DPE/DpeInfra"));
const DpeAchievements = lazy(() => import("./Departments/DPE/DpeAchievements"));
const DpeSports = lazy(() => import("./Departments/DPE/DpeSports"));
const News = lazy(() => import("./NewsAndEvents/News"));
const NationalYouthFest = lazy(() => import("./NewsAndEvents/newsSection/national-youth-festival-2024"));
const TexasReview2024 = lazy(() => import("./NewsAndEvents/newsSection/texas-review-2024.js"));
const TV9KABSummitAtHitex = lazy(() => import("./NewsAndEvents/newsSection/TV9_KAB_SUMMIT_AT_HITEX_DAY_1.js"));
const Circulars = lazy(() => import("./NewsAndEvents/Circulars"));
const SwecRadio = lazy(() => import("./NewsAndEvents/SwecRadio"));
const Events = lazy(() => import("./NewsAndEvents/Events"));
const WomensDayCelebrations2023 = lazy(() => import("./NewsAndEvents/eventsSaection/WomensDayCelebrations2023"));
const UGSAutonomousInstitute = lazy(() => import("./NewsAndEvents/newsSection/UGC-autonomous-institute"));
const RepublicDayCelebrations2024 = lazy(() => import("./NewsAndEvents/eventsSaection/RepublicDayCelebrations2024"));
const IDEATHON2k23 = lazy(() => import("./NewsAndEvents/eventsSaection/IDEATHON2k23"));
const ISROIndustrialVisit2Year = lazy(() => import("./NewsAndEvents/eventsSaection/ISROIndustrialVisit2Year"));
const ProjectExpo2022 = lazy(() => import("./NewsAndEvents/eventsSaection/ProjectExpo2022"));
const IndependentDay2022 = lazy(() => import("./NewsAndEvents/eventsSaection/IndependentDay2022"));
const InternationalYogaDay2022 = lazy(() => import("./NewsAndEvents/eventsSaection/InternationalYogaDay2022.js"));
const VisitVisro2022 = lazy(() => import("./NewsAndEvents/eventsSaection/VisitVisro2022"));
const Alumni2122 = lazy(() => import("./NewsAndEvents/eventsSaection/Alumni2122"));
const WorkshoponAndroidandmobileAps = lazy(() => import("./NewsAndEvents/eventsSaection/WorkshoponAndroidandmobileAps"));
const HackathonCertificateDistribution = lazy(() => import("./NewsAndEvents/eventsSaection/HackathonCertificateDistribution"));
const ISROIndustrialVisit2021 = lazy(() => import("./NewsAndEvents/eventsSaection/ISROIndustrialVisit2021"));
const HackathonValedictoryPhotography = lazy(() => import("./NewsAndEvents/eventsSaection/HackathonValedictoryPhotography.js"));
const AntidrugAbuseCommittee = lazy(() => import("./NewsAndEvents/eventsSaection/AntidrugAbuseCommittee"));
const WomensDay2022 = lazy(() => import("./NewsAndEvents/eventsSaection/WomensDay2022"));
const HairDonationDrive = lazy(() => import("./NewsAndEvents/eventsSaection/HairDonationDrive"));
const ObeWorkShop2022 = lazy(() => import("./NewsAndEvents/eventsSaection/ObeWorkShop2022"));
const Republic2022 = lazy(() => import("./NewsAndEvents/eventsSaection/Republic2022"));
const InductionProgram2122 = lazy(() => import("./NewsAndEvents/eventsSaection/InductionProgram2122"));
const Bathukamma2021 = lazy(() => import("./NewsAndEvents/eventsSaection/Bathukamma2021"));
const YuvaSamarthya2021 = lazy(() => import("./NewsAndEvents/eventsSaection/YuvaSamarthya2021"));
const AICTESponsoredSTTP = lazy(() => import("./NewsAndEvents/eventsSaection/AICTESponsoredSTTP"));
const WomensDay2021 = lazy(() => import("./NewsAndEvents/eventsSaection/WomensDay2021"));
const RepublicDayCelebrations2021 = lazy(() => import("./NewsAndEvents/eventsSaection/RepublicDayCelebrations2021"));
const VidhYouth4 = lazy(() => import("./NewsAndEvents/eventsSaection/VidhYouth4"));
const MouWithStudyOrbit = lazy(() => import("./NewsAndEvents/eventsSaection/MouWithStudyOrbit"));
const IsoCertificate = lazy(() => import("./NewsAndEvents/eventsSaection/IsoCertificate"));
const AlumniMeet2020 = lazy(() => import("./NewsAndEvents/eventsSaection/AlumniMeet2020"));
const AICTEATALFDP = lazy(() => import("./NewsAndEvents/eventsSaection/AICTEATALFDP"));
const FirstYearOrientationDay2020 = lazy(() => import("./NewsAndEvents/eventsSaection/FirstYearOrientationDay2020"));
const MOUwithTexasReview = lazy(() => import("./NewsAndEvents/eventsSaection/MOUwithTexasReview.js"));
const STTPonBlockchain = lazy(() => import("./NewsAndEvents/eventsSaection/STTPonBlockchain"));
const ICDML2020 = lazy(() => import("./NewsAndEvents/eventsSaection/ICDML2020"));
const FDPandWEBINARS = lazy(() => import("./NewsAndEvents/eventsSaection/FDPandWEBINARS"));
const FDPandSDP = lazy(() => import("./NewsAndEvents/eventsSaection/FDPandSDP"));
const Selfdefenceskills = lazy(() => import("./NewsAndEvents/eventsSaection/Selfdefenceskills"));
const SayNoPlastic = lazy(() => import("./NewsAndEvents/eventsSaection/SayNoPlastic"));
const CybersecurityAmbassadorSeminar = lazy(() => import("./NewsAndEvents/eventsSaection/CybersecurityAmbassadorSeminar"));
const MBAworkshop = lazy(() => import("./NewsAndEvents/eventsSaection/MBAworkshop"));
const HigherEducationseminar = lazy(() => import("./NewsAndEvents/eventsSaection/HigherEducationseminar"));
const EngineeringEducatorsAwards = lazy(() => import("./NewsAndEvents/eventsSaection/EngineeringEducatorsAwards"));
const SheTeamsProgramme = lazy(() => import("./NewsAndEvents/eventsSaection/SheTeamsProgramme"));
const BathukammaCelebrations2019 = lazy(() => import("./NewsAndEvents/eventsSaection/BathukammaCelebrations2019"));
const KillCancerEssaywritingcompetition = lazy(() => import("./NewsAndEvents/eventsSaection/KillCancerEssaywritingcompetition"));
const MOU2020 = lazy(() => import("./NewsAndEvents/eventsSaection/MOU2020"));
const JntuhPrincipalMeet = lazy(() => import("./NewsAndEvents/eventsSaection/JntuhPrincipalMeet"));
const IsoCertificate2015 = lazy(() => import("./NewsAndEvents/eventsSaection/IsoCertificate2015 "));
const MBAOrientationDay2019 = lazy(() => import("./NewsAndEvents/eventsSaection/MBAOrientationDay2019"));
const Events2019 = lazy(() => import("./NewsAndEvents/eventsSaection/Events2019"));
const VIDHYOUTH18 = lazy(() => import("./NewsAndEvents/eventsSaection/VIDHYOUTH18"));
const Sports2018 = lazy(() => import("./NewsAndEvents/eventsSaection/Sports2018"));
const Maithri2018 = lazy(() => import("./NewsAndEvents/eventsSaection/Maithri2018"));
const WorkshopsandSeminars = lazy(() => import("./NewsAndEvents/eventsSaection/WorkshopsandSeminars"));
const SportsAwards201819 = lazy(() => import("./NewsAndEvents/eventsSaection/SportsAwards201819"));
const Pratiyogita2K19 = lazy(() => import("./NewsAndEvents/eventsSaection/Pratiyogita2K19"));
const QubitMGITTechnicalfest = lazy(() => import("./NewsAndEvents/eventsSaection/QubitMGITTechnicalfest"));
const SriSamvidhan2019 = lazy(() => import("./NewsAndEvents/eventsSaection/SriSamvidhan2019"));
const KillCancerAwareness5KMarathon = lazy(() => import("./NewsAndEvents/eventsSaection/KillCancerAwareness5KMarathon.js"));
const Maithri2019FlashmobandSong = lazy(() => import("./NewsAndEvents/eventsSaection/Maithri2019FlashmobandSong"));
const SwecsGallery = lazy(() => import("./NewsAndEvents/SwecsGallery"));
const EducationMatterscounseling = lazy(() => import("./NewsAndEvents/gallerySection/EducationMatterscounseling"));
const InfrastructureManagement = lazy(() => import("./NewsAndEvents/gallerySection/InfrastructureManagement"));
const FlashMob2018 = lazy(() => import("./NewsAndEvents/gallerySection/FlashMob2018"));
const Farewell2018 = lazy(() => import("./NewsAndEvents/gallerySection/Farewell2018"));
const OrientationProgram2016 = lazy(() => import("./NewsAndEvents/gallerySection/OrientationProgram2016"));
const ISBdebate2016 = lazy(() => import("./NewsAndEvents/gallerySection/ISBdebate2016"));
const NSSProgramme = lazy(() => import("./NewsAndEvents/gallerySection/NSSProgramme"));
const IdeathonMediaCoverage = lazy(() => import("./NewsAndEvents/newsSection/IdeathonMediaCoverage.js"));
const FestSriSamvidhan = lazy(() => import("./NewsAndEvents/newsSection/FestSriSamvidhan"));
const NAACGrade = lazy(() => import("./NewsAndEvents/newsSection/NAACGrade"));
const WalkinInteriview = lazy(() => import("./NewsAndEvents/newsSection/WalkinInteriview.js"));
const OnlineDataScienceMachine = lazy(() => import("./NewsAndEvents/newsSection/OnlineDataScienceMachine"));
const IIRSCertifcate = lazy(() => import("./NewsAndEvents/newsSection/IIRSCertifcate"));
const JNTUHCovid19Circular = lazy(() => import("./NewsAndEvents/newsSection/JNTUHCovid19Circular"));
const SHEteam5thanniversary = lazy(() => import("./NewsAndEvents/newsSection/SHEteam5thanniversary"));
const NSSNews = lazy(() => import("./NewsAndEvents/newsSection/NSSNews"));
const FreshersDay = lazy(() => import("./NewsAndEvents/newsSection/FreshersDay"));
const NationalSportsDaycelebration = lazy(() => import("./NewsAndEvents/newsSection/NationalSportsDaycelebration.js"));
const VidhYouth2 = lazy(() => import("./NewsAndEvents/newsSection/VidhYouth2"));
const NSSEventSistersforaChange = lazy(() => import("./NewsAndEvents/newsSection/NSSEventSistersforaChange"));
const GraduationDay2019 = lazy(() => import("./NewsAndEvents/newsSection/GraduationDay2019"));
const FirstYearOrientationDay2019 = lazy(() => import("./NewsAndEvents/newsSection/FirstYearOrientationDay2019"));
const NBACertified = lazy(() => import("./NewsAndEvents/newsSection/NBACertified"));
const Pratiyogita2K19News = lazy(() => import("./NewsAndEvents/newsSection/Pratiyogita2K19News"));
const TheIQPrincess2019 = lazy(() => import("./NewsAndEvents/newsSection/TheIQPrincess2019"));
const Maithri2K19 = lazy(() => import("./NewsAndEvents/newsSection/Maithri2K19"));
const Maithri2019News = lazy(() => import("./NewsAndEvents/newsSection/Maithri2019News"));
const JBRECTechnicalFest = lazy(() => import("./NewsAndEvents/newsSection/JBRECTechnicalFest"));
const EngineeringEducatorsAwardsNews = lazy(() => import("./NewsAndEvents/newsSection/EngineeringEducatorsAwardsNews"));
const NausainikCamp = lazy(() => import("./NewsAndEvents/newsSection/NausainikCamp"));
const VIDHYOUTH18news = lazy(() => import("./NewsAndEvents/newsSection/VIDHYOUTH18news"));
const CollegeDayNews = lazy(() => import("./NewsAndEvents/newsSection/CollegeDayNews"));
const SWECPlacements = lazy(() => import("./NewsAndEvents/newsSection/swecplacements2023-24"));
const SportsNews = lazy(() => import("./NewsAndEvents/newsSection/SportsNews"));
const Graduationday2023 = lazy(() => import("./NewsAndEvents/newsSection/graduation-day-2023"));
const Prakalp = lazy(() => import("./NewsAndEvents/newsSection/it-project-expo-prakalp"));
const ECEProject2023 = lazy(() => import("./NewsAndEvents/newsSection/ece-project-expo-2023"));
const CSEFaculty = lazy(() => import("./Departments/cseDepartmentLinks/CSEFaculty"));
// const Maithri2019 =lazy( ()=>import( "./NewsAndEvents/newsSection/Maithri2019"));
const ItFaculty = lazy(() => import('./Departments/ITlinks/ItFaculty'));
const EEEFaculty = lazy(() => import('./Departments/EEELinks/EEEFaculty'));
const P2122 = lazy(() => import("./TrainingAndPlacements/P2122"));
const P2223 = lazy(() => import("./TrainingAndPlacements/P2223"));
const SkillEnhancement = lazy(() => import("./TrainingAndPlacements/SkillEnhancement"));
const IntellectualPropertyRights = lazy(() => import('./footer/IntellectualPropertyRights'));
const TermsConditions = lazy(() => import("./footer/TermsConditions"));
const ExtensionActivities = lazy(() => import("./footer/ExtensionActivities"));
const WebsitePolicies = lazy(() => import("./footer/WebsitePolicies"));
const CopyrightPolicy = lazy(() => import("./footer/CopyrightPolicy"));
const ReachUs = lazy(() => import("./footer/ReachUs"));
const Location = lazy(() => import("./footer/Location"));
const EventsVideos = lazy(() => import("./events/EventsVideos"));
const Committie = lazy(() => import("./About/Committie.js"));
const NAAC = lazy(() => import("./NAAC.js"));
const NAAC2021 = lazy(() => import("./NAAC-2021.js"));
const NAACAQAR = lazy(() => import("./NAAC-AQAR.js"));
const NAACLogin = lazy(() => import("./NAACLogin.js"));
const JobPlacememts = lazy(() => import("./NewsAndEvents/newsSection/JobPlacements.js"));
const GadwalSolarStreetLightProject = lazy(() => import("./NewsAndEvents/newsSection/GadwalSolarStreetLightProject.js"));
const ECERoboticsWorkshop = lazy(() => import("./NewsAndEvents/eventsSaection/ECERoboticsWorkshop2024.js"));
const CocaColaBeverages = lazy(() => import("./NewsAndEvents/eventsSaection/IndustrialVisitCocaColaBeverages2024.js"));
const InternationalYogaDay2024 = lazy(() => import("./NewsAndEvents/eventsSaection/InternationalYogaDay2024.js"));
const InternationalDrugAbuseDay2024 = lazy(() => import("./NewsAndEvents/eventsSaection/InternationalDrugAbuseDay2024.js"));
const AccenturePptOnJuly31st2024 = lazy(()=>import("./NewsAndEvents/newsSection/AccenturePptOnJuly31st2024.js"))


// imports end here 


function App() {
  return (
    <React.Fragment>
      <section>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route path="/" exact>
              <MainSlider />
            </Route>
            <Route path="/home" exact>
              <MainSlider />
            </Route>
            <Route path="/NAAC" exact>
              <NAAC />
            </Route>
            <Route path="/NAAC-2021" exact>
              <NAAC2021 />
            </Route>
            <Route path="/NAAC-AQAR" exact>
              <NAACAQAR />
            </Route>
            <Route path="/NAACLogin" exact>
              <NAACLogin />
            </Route>
            <Route path="/about/about-college" exact>
              <AboutClg />
            </Route>
            <Route path="/about/dean-academics" exact>
              <DeanAcademics />
            </Route>
            <Route path="/about/dean-admissions" exact>
              <DeanAdmissions />
            </Route>
            <Route path="/about/board-of-studies" exact>
              <BOS />
            </Route>
            <Route path="/about/management" exact>
              <Management />
            </Route>
            <Route path="/about/recognitions" exact>
              <SWECRecognitions />
            </Route>
            <Route path="/about/aicte" exact>
              <Aicte />
            </Route>
            <Route path="/about/governing-body" exact>
              <GoverningBody />
            </Route>
            <Route path="/about/principal" exact>
              <Prncipal />
            </Route>
            <Route path="/about/committee" exact>
              <Committie />
            </Route>
            <Route path="/about/mandatory-disclosure" exact>
              <Disclosure />
            </Route>
            <Route path="/about/service-rules" exact>
              <ServiceRules />
            </Route>
            <Route path="/about/organogram" exact>
              <Organogram />
            </Route>
            <Route path="/about/salient-features" exact>
              <SalientFeatures />
            </Route>
            <Route path="/dept/cse" exact>
              <CSE />
            </Route>
            <Route path="/dept/cse-ds/" exact>
              <CseDs />
            </Route>
            <Route path="/dept/ai-ml/" exact>
              <CseAIMl />
            </Route>
            <Route path="/dept/ece/" exact>
              <DECE />
            </Route>
            <Route path="/dept/it/" exact>
              <IT />
            </Route>
            <Route path="/dept/eee/" exact>
              <EEE />
            </Route>
            <Route path="/dept/mba/" exact>
              <MBA />
            </Route>
            <Route path="/dept/s-and-h/" exact>
              <SandH />
            </Route>
            <Route path="/dept/dpe/" exact>
              <DPE />
            </Route>
            <Route path="/contact-us/" exact>
              <Contact />
            </Route>
            <Route path="/r-and-d/innovation-incubation/" exact>
              <InnovationIncubation />
            </Route>
            <Route path="/r-and-d/grants-received/" exact>
              <GrantsReceived />
            </Route>
            <Route path="/r-and-d/research-guide/" exact>
              <ResearchGuide />
            </Route>
            <Route path="/r-and-d/industry-institute-interaction/" exact>
              <IndustryInstituteInteraction />
            </Route>
            <Route path="/iqac/quality-policy/" exact>
              <QualityPolicy />
            </Route>
            <Route path="/iqac/quality-education/" exact>
              <QualityEducation />
            </Route>
            <Route path="/iqac/" exact>
              <IQAC />
            </Route>
            <Route path="/nirf/" exact>
              <NIRF />
            </Route>
            <Route path="/overall/" exact>
              <Overall />
            </Route>
            <Route path="/innovation/" exact>
              <Innovation />
            </Route>
            <Route path="/feedback/" exact>
              <Feedback />
            </Route>
            <Route path="/testimonials/" exact>
              <Testimonials />
            </Route>
            <Route path="/grievance-cell/" exact>
              <GiveranceCell />
            </Route>
            <Route path="/alumni/" exact>
              <Alumni />
            </Route>
            <Route path="/professional-societies/" exact>
              <ProfessionalSocieties />
            </Route>
            <Route path="/edc/" exact>
              <EDC />
            </Route>
            <Route path="/infrastructure/central-facilities/" exact>
              <CentralFacilities />
            </Route>
            <Route path="/infrastructure/transport/" exact>
              <Transport />
            </Route>
            <Route path="/infrastructure/computer-centre/" exact>
              <ComputerCenter />
            </Route>
            <Route path="/infrastructure/swec-campus-hostel/" exact>
              <Hostel />
            </Route>
            <Route path="/infrastructure/ict/" exact>
              <ICT />
            </Route>
            <Route path="/activities/obe/" exact>
              <OBE />
            </Route>
            <Route path="/activities/ncc/" exact>
              <NCC />
            </Route>
            <Route path="/activities/nss/" exact>
              <NSS />
            </Route>
            <Route path="/activities/sswec/" exact>
              <SSWES />
            </Route>
            <Route path="/activities/college-magazine/" exact>
              <ClgMagzine />
            </Route>
            <Route path="/activities/GreenCampus/" exact>
              <GreenCampus />
            </Route>
            <Route path="/activities/it-finishing-school/" exact>
              <ITFinishingSchool />
            </Route>
            <Route path="/Results-ofB.Tech-2018-22-batch-and-MBA-2020-22-batch" exact>
              <ResultUpload />
            </Route>
            <Route path="/about/admissions" exact>
              <Admissions />
            </Route>
            <Route path="/training-and-placements/" exact>
              <TPD />
            </Route>
            <Route path="/training-and-placements/2020-21" exact>
              <P2021 />
            </Route>
            <Route path="/dept/library" exact>
              <Library />
            </Route>
            <Route path="/academics-calendar/" exact>
              <Jntuh />
            </Route>
            <Route path="/academic-calendar-mba/" exact>
              <AcaMba />
            </Route>
            <Route path="/syllabus/" exact>
              <JntuSyllubus />
            </Route>
            <Route path="/time-tables/" exact>
              <JntuTimeTable />
            </Route>
            <Route path="/notifications/" exact>
              <Notifications />
            </Route>
            <Route path="/feedback-forms/" exact>
              <FeedbackForms />
            </Route>
            <Route path="/holiday-list/" exact>
              <HolidaysList />
            </Route>
            <Route path="/autonomous-academic-calendar/" exact>
              <AcademicAutonomous />
            </Route>
            <Route path="/academics/" exact>
              <Academics />
            </Route>
            <Route path="/academics-calendar/autonomous-syllabus" exact>
              <AutonomousSyllubus />
            </Route>
            <Route path="/training-and-placements/2019-20" exact>
              <P1920 />
            </Route>
            <Route path="/training-and-placements/2018-19" exact>
              <P1819 />
            </Route>
            <Route path="/training-and-placements/2017-18" exact>
              <P1718 />
            </Route>
            <Route path="/training-and-placements/2016-17" exact>
              <P1617 />
            </Route>
            <Route path="/training-and-placements/2015-16" exact>
              <P1516 />
            </Route>
            <Route path="/training-and-placements/2014-15" exact>
              <P1415 />
            </Route>
            <Route path="/training-and-placements/2013-14" exact>
              <P1314 />
            </Route>
            <Route path="/training-and-placements/2012-13" exact>
              <P1213 />
            </Route>
            <Route path="/training-and-placements/technical-training" exact>
              <TechnicalTraining />
            </Route>
            <Route path="/training-and-placements/task" exact>
              <Task />
            </Route>
            <Route path="/training-and-placements/dept-wise-placements" exact>
              <DeptWise />
            </Route>
            <Route path="/dept/cse/vision-mission" exact>
              <CseVision />
            </Route>
            <Route path="/dept/cse/po-and-pso" exact>
              <CSEpo />
            </Route>
            <Route path="/dept/cse/hod-profile" exact>
              <CSEHod />
            </Route>
            <Route path="/dept/cse/student-projects" exact>
              <CSEStudentProjects />
            </Route>
            <Route path="/dept/cse/industry-visits" exact>
              <IndustryVisits />
            </Route>
            <Route path="/dept/cse/student-development-programs" exact>
              <CSEStudentDPrograms />
            </Route>
            <Route path="/dept/cse/syllabus" exact>
              <CSESyllubus />
            </Route>
            <Route path="/dept/cse/placements" exact>
              <CSEPlacements />
            </Route>
            <Route path="/dept/cse/professional-bodies" exact>
              <CSEProffessional />
            </Route>
            <Route path="/dept/cse/magazines" exact>
              <CSENewsLetter />
            </Route>
            <Route path="/dept/cse/faculty-publications" exact>
              <CSEFacultyPublications />
            </Route>
            <Route path="/dept/cse/faculty-innovations" exact>
              <FacultyInnovation />
            </Route>
            <Route path="/dept/cse/workshops" exact>
              <CSEWorkShop />
            </Route>
            <Route path="/dept/cse/books-published" exact>
              <CSEbooksPublished />
            </Route>
            <Route path="/dept/cse/patent-publications" exact>
              <PatentPublications />
            </Route>
            <Route path="/dept/cse/laboratory-projects" exact>
              <CSELaboratoryProjects />
            </Route>
            <Route path="/dept/cse/academic-sponsered" exact>
              <CSEAcademicSponser />
            </Route>
            <Route path="/dept/cse/bridge-course" exact>
              <CSEBridgeCourse />
            </Route>
            <Route path="/dept/cse/course-files" exact>
              <CSECourseFiles />
            </Route>
            <Route path="/dept/cse/laboratories" exact>
              <CSELaboratories />
            </Route>
            <Route path="/dept/cse/department-library" exact>
              <CSELibrary />
            </Route>
            <Route path="/dept/cse/department-mous" exact>
              <CSEMous />
            </Route>
            <Route path="/dept/cse-ds" exact>
              <CSEDS />
            </Route>
            <Route path="/dept/ece-ds/vision-mission-peos" exact>
              <EceVision />
            </Route>
            <Route path="/dept/ece-ds/po-and-pso" exact>
              <EcePo />
            </Route>
            <Route path="/dept/ece-ds/hod" exact>
              <EceHod />
            </Route>
            <Route path="/dept/ece-ds/ece-faculty" exact>
              <EceFaculty />
            </Route>
            <Route path="/dept/ece-ds/department-academic-calendar" exact>
              <EceCalender />
            </Route>
            <Route path="/dept/ece-ds/student-projects" exact>
              <EceStudentProjects />
            </Route>
            <Route path="/dept/ece-ds/industry-internship" exact>
              <EceIndustryIntern />
            </Route>
            <Route path="/dept/ece-ds/industry-visits" exact>
              <EceIndustryVisits />
            </Route>
            <Route path="/dept/ece-ds/student-development-programs" exact>
              <EceSDP />
            </Route>
            <Route path="/dept/ece-ds/syllabus" exact>
              <EceSyllabus />
            </Route>
            <Route path="/dept/ece-ds/result-analysis" exact>
              <EceAnalysis />
            </Route>
            <Route path="/dept/ece-ds/placements" exact>
              <EcePlacements />
            </Route>
            <Route path="/dept/ece-ds/professional-bodies" exact>
              <EceProffessionalBodies />
            </Route>
            <Route path="/dept/ece-ds/magazines" exact>
              <EceMagazines />
            </Route>
            <Route path="/dept/ece-ds/student-publications" exact>
              <EceStudentPublications />
            </Route>
            <Route path="/dept/ece-ds/faculty-innovations" exact>
              <ECEFacultyInnovations />
            </Route>
            <Route path="/dept/ece-ds/workshops" exact>
              <ECEworkshops />
            </Route>
            <Route path="/dept/ece-ds/faculty-performance" exact>
              <EceFacultyPerprmance />
            </Route>
            <Route path="/dept/ece-ds/laboratory-projects" exact>
              <EceLaboratoryProjects />
            </Route>
            <Route path="/dept/ece-ds/academic-sponsered" exact>
              <EceAcademicSponsered />
            </Route>
            <Route path="/dept/ece-ds/course-files" exact>
              <EceCourseFiles />
            </Route>
            <Route path="/dept/ece-ds/department-library" exact>
              <EceDepartmentLibrary />
            </Route>
            <Route path="/dept/ece-ds/department-mous" exact>
              <EceMous />
            </Route>
            <Route path="/dept/it/vision-mission-peos" exact>
              <ItVision />
            </Route>
            <Route path="/dept/it/po-and-pso" exact>
              <ItPO />
            </Route>
            <Route path="/dept/it/hod-profile" exact>
              <ITHOD />
            </Route>
            <Route path="/dept/it/student-projects" exact>
              <ITSprojects />
            </Route>
            <Route path="/dept/it/industry-internship" exact>
              <ITindustryIntern />
            </Route>
            <Route path="/dept/it/industry-visits" exact>
              <ItIndustryVisits />
            </Route>
            <Route path="/dept/it/syllabus" exact>
              <ItSyllabus />
            </Route>
            <Route path="/dept/it/placements" exact>
              <ItPlacements />
            </Route>
            <Route path="/dept/it/magazines" exact>
              <Itmagazines />
            </Route>
            <Route path="/dept/it/faculty-publications" exact>
              <ItFacultyAchievements />
            </Route>

            <Route path="/dept/it/faculty-innovations" exact>
              <ItFaxultyInnovations />
            </Route>
            <Route path="/dept/it/workshops" exact>
              <ItWorkshops />
            </Route>
            <Route path="/dept/it/bridge-course" exact>
              <ItBridgeCourse />
            </Route>
            <Route path="/dept/it/department-library" exact>
              <ItLibrary />
            </Route>
            <Route path="/dept/eee/vision-mission-peos" exact>
              <EEEVision />
            </Route>
            <Route path="/dept/eee/po-and-pso" exact>
              <EEEPoAnsPSO />
            </Route>
            <Route path="/dept/eee/industry-internship" exact>
              <EEEIndustryInterm />
            </Route>
            <Route path="/dept/eee/industry-visits" exact>
              <EEEIndustryVisits />
            </Route>
            <Route path="/dept/eee/student-development-programs" exact>
              <EEESDP />
            </Route>
            <Route path="/dept/eee/syllabus" exact>
              <EEESyllabus />
            </Route>
            <Route path="/dept/eee/placements" exact>
              <EEEPlacements />
            </Route>
            <Route path="/dept/eee/professional-bodies" exact>
              <EEEProfessionalBodies />
            </Route>
            <Route path="/dept/eee/magazines" exact>
              <EEEMagazines />
            </Route>
            <Route path="/dept/eee/student-publications" exact>
              <EEEStudentAchievements />
            </Route>
            <Route path="/dept/eee/faculty-innovations" exact>
              <EEEFacultyInnovation />
            </Route>
            <Route path="/dept/eee/workshops" exact>
              <EEEworkshops />
            </Route>
            <Route path="/dept/eee/faculty-performance" exact>
              <EEEFacultyPerformance />
            </Route>
            <Route path="/dept/eee/faculty-performance" exact>
              <EEEFacultyPerformance />
            </Route>
            <Route path="/dept/eee/laboratory-projects" exact>
              <EEEEandD />
            </Route>
            <Route path="/dept/eee/academic-sponsered" exact>
              <EEEAcademicSponser />
            </Route>
            <Route path="/dept/eee/course-files" exact>
              <EEECourseFiles />
            </Route>
            <Route path="/dept/eee/department-library" exact>
              <EEELibrary />
            </Route>
            <Route path="/dept/eee/department-mous" exact>
              <EEEMous />
            </Route>
            <Route path="/dept/eee/academic-plan" exact>
              <EEEAcademicPlan />
            </Route>
            <Route path="/dept/mba/vision-mission-peos" exact>
              <MbaVision />
            </Route>
            <Route path="/dept/mba/po-and-pso" exact>
              <MbaPoandPso />
            </Route>
            <Route path="/dept/mba/hod-profile" exact>
              <MbaHod />
            </Route>
            <Route path="/dept/mba/mba-faculty-list" exact>
              <MbaFaculty />
            </Route>
            <Route path="/dept/mba/mentor-list" exact>
              <MbaMentorList />
            </Route>
            <Route path="/dept/mba/infrastructure" exact>
              <MbaInfrastructure />
            </Route>
            <Route path="/dept/mba/syllabus" exact>
              <MbaSyllabus />
            </Route>
            <Route path="/dept/mba/remedial-class" exact>
              <MbaRemedialClass />
            </Route>
            <Route path="/dept/mba/bridge-course" exact>
              <MbaBridgeCourse />
            </Route>
            <Route path="/dept/mba/workshop" exact>
              <MbaWorkShop />
            </Route>
            <Route path="/dept/mba/activities" exact>
              <MbaActivities />
            </Route>
            <Route path="/dept/mba/innovative-teaching" exact>
              <MbaInnovativeTeachig />
            </Route>
            <Route path="/dept/mba/placements" exact>
              <MbaPlacements />
            </Route>
            <Route path="/dept/s-and-h/vision-mission-peos" exact>
              <Svision />
            </Route>
            <Route path="/dept/s-and-h/hod-profile" exact>
              <SHod />
            </Route>
            <Route path="/dept/s-and-h/faculty-list" exact>
              <SandHFaculty />
            </Route>
            <Route path="/dept/s-and-h/activities" exact>
              <Hactivities />
            </Route>
            <Route path="/dept/dpe/hod-profile" exact>
              <DpeHod />
            </Route>
            <Route path="/dept/dpe/infrastructure" exact>
              <DpeInfra />
            </Route>
            <Route path="/dept/dpe/achievements" exact>
              <DpeAchievements />
            </Route>
            <Route path="/dept/dpe/sports" exact>
              <DpeSports />
            </Route>
            <Route path="/news-and-events/news" exact>
              <News />
            </Route>
            <Route path="/news-and-events/circulars" exact>
              <Circulars />
            </Route>
            <Route path="/news-and-events/swec-radio" exact>
              <SwecRadio />
            </Route>
            <Route path="/news-and-events/events" exact>
              <Events />
            </Route>
            <Route path="/news-and-events/events/womens-day-2023" exact>
              <WomensDayCelebrations2023 />
            </Route>
            <Route path="/news-and-events/events/republic-day-celebrations-2024" exact>
              <RepublicDayCelebrations2024 />
            </Route>
            <Route path="/news-and-events/events/ideathon-2023" exact>
              <IDEATHON2k23 />
            </Route>
            <Route path="/news-and-events/events/isro-industrial-2023" exact>
              <ISROIndustrialVisit2Year />
            </Route>
            <Route path="/news-and-events/events/project-expo-2022" exact>
              <ProjectExpo2022 />
            </Route>
            <Route path="/news-and-events/events/independence-day-celebration-2022" exact>
              <IndependentDay2022 />
            </Route>
            <Route path="/news-and-events/events/yoga-day-2022" exact>
              <InternationalYogaDay2022 />
            </Route>
            <Route path="/news-and-events/events/visit-isro-2022" exact>
              <VisitVisro2022 />
            </Route>
            <Route path="/news-and-events/events/alumni-21-22" exact>
              <Alumni2122 />
            </Route>
            <Route path="/news-and-events/events/workshop-on-android-and-mobile-development" exact>
              <WorkshoponAndroidandmobileAps />
            </Route>
            <Route path="/news-and-events/events/hackathon-certificate-distribution" exact>
              <HackathonCertificateDistribution />
            </Route>
            <Route path="/news-and-events/events/isro-industrial-visit" exact>
              <ISROIndustrialVisit2021 />
            </Route>
            <Route path="/news-and-events/events/hackathon-valedictory-photography" exact>
              <HackathonValedictoryPhotography />
            </Route>
            <Route path="/news-and-events/events/anti-drug-abuse-committee" exact>
              <AntidrugAbuseCommittee />
            </Route>
            <Route path="/news-and-events/events/womens-day-celebration-2022" exact>
              <WomensDay2022 />
            </Route>
            <Route path="/news-and-events/events/hair-donation-drive-2022" exact>
              <HairDonationDrive />
            </Route>
            <Route path="/news-and-events/events/obe-workshop-feb-2022" exact>
              <ObeWorkShop2022 />
            </Route>
            <Route path="/news-and-events/events/republic-day-2022" exact>
              <Republic2022 />
            </Route>
            <Route path="/news-and-events/events/induction-program-2021-22" exact>
              <InductionProgram2122 />
            </Route>
            <Route path="/news-and-events/events/bathukamma-celebrations-2021" exact>
              <Bathukamma2021 />
            </Route>
            <Route path="/news-and-events/events/yuva-samarthya" exact>
              <YuvaSamarthya2021 />
            </Route>
            <Route path="/news-and-events/events/aicte-sttp-on-blockchain" exact>
              <AICTESponsoredSTTP />
            </Route>
            <Route path="/news-and-events/events/womens-day-celebration-2021" exact>
              <WomensDay2021 />
            </Route>
            <Route path="/news-and-events/events/republic-day-2021" exact>
              <RepublicDayCelebrations2021 />
            </Route>
            <Route path="/news-and-events/events/vidhyouth-4.0" exact>
              <VidhYouth4 />
            </Route>
            <Route path="/news-and-events/events/mou-studyorbit" exact>
              <MouWithStudyOrbit />
            </Route>
            <Route path="/news-and-events/events/iso-certificate" exact>
              <IsoCertificate />
            </Route>
            <Route path="/news-and-events/events/alumni-meet-2020" exact>
              <AlumniMeet2020 />
            </Route>
            <Route path="/news-and-events/events/aicte-atal-fdp" exact>
              <AICTEATALFDP />
            </Route>
            <Route path="/news-and-events/events/first-year-orientation-day-2020" exact>
              <FirstYearOrientationDay2020 />
            </Route>
            <Route path="/news-and-events/events/mou-texas-review" exact>
              <MOUwithTexasReview />
            </Route>
            <Route path="/news-and-events/events/sttp-on-blockchain-technology" exact>
              <STTPonBlockchain />
            </Route>
            <Route path="/news-and-events/events/icdml" exact>
              <ICDML2020 />
            </Route>
            <Route path="/news-and-events/events/fdp-and-webinars" exact>
              <FDPandWEBINARS />
            </Route>
            <Route path="/news-and-events/events/mba-fdp-and-sdp" exact>
              <FDPandSDP />
            </Route>
            <Route path="/news-and-events/events/self-defence-skills" exact>
              <Selfdefenceskills />
            </Route>
            <Route path="/news-and-events/events/say-no-to-plastic" exact>
              <SayNoPlastic />
            </Route>
            <Route path="/news-and-events/events/mba-workshop" exact>
              <MBAworkshop />
            </Route>
            <Route path="/news-and-events/events/rowan-university-higher-education-seminar" exact>
              <HigherEducationseminar />
            </Route>
            <Route path="/news-and-events/events/award-and-felicitation-by-education-matters" exact>
              <EngineeringEducatorsAwards />
            </Route>
            <Route path="/news-and-events/events/she-teams" exact>
              <SheTeamsProgramme />
            </Route>
            <Route path="/news-and-events/events/bathukamma_celebrations2019" exact>
              <BathukammaCelebrations2019 />
            </Route>
            <Route path="/news-and-events/events/bathukamma_celebrations2019" exact>
              <BathukammaCelebrations2019 />
            </Route>
            <Route path="/news-and-events/events/kill-cancer-essay-writing-competition" exact>
              <KillCancerEssaywritingcompetition />
            </Route>
            <Route path="/news-and-events/events/mou-education-matters" exact>
              <MOU2020 />
            </Route>
            <Route path="/news-and-events/events/jntuh-principal-meet" exact>
              <JntuhPrincipalMeet />
            </Route>
            <Route path="/news-and-events/events/iso-certification-swec" exact>
              <IsoCertificate2015 />
            </Route>
            <Route path="/news-and-events/events/mba-orentiation-day" exact>
              <MBAOrientationDay2019 />
            </Route>
            <Route path="/news-and-events/events/events2019" exact>
              <Events2019 />
            </Route>
            <Route path="/news-and-events/events/pratiyogita2019" exact>
              <Pratiyogita2K19 />
            </Route>
            <Route path="/news-and-events/events/qubit-mgit-technical-fest" exact>
              <QubitMGITTechnicalfest />
            </Route>
            <Route path="/news-and-events/events/sri-samvidhan-2019" exact>
              <SriSamvidhan2019 />
            </Route>
            <Route path="/news-and-events/events/kill-cancer-awareness" exact>
              <KillCancerAwareness5KMarathon />
            </Route>
            <Route path="/news-and-events/events/flashmob-2019" exact>
              <Maithri2019FlashmobandSong />
            </Route>
            <Route path="/news-and-events/events/sports-awards-2018-19" exact>
              <SportsAwards201819 />
            </Route>
            <Route path="/news-and-events/events/vidh-youth-2018" exact>
              <VIDHYOUTH18 />
            </Route>
            <Route path="/news-and-events/events/sports-2018" exact>
              <Sports2018 />
            </Route>
            <Route path="/news-and-events/events/maithri-2018" exact>
              <Maithri2018 />
            </Route>
            <Route path="/news-and-events/events/workshop-and-seminar" exact>
              <WorkshopsandSeminars />
            </Route>
            <Route path="/news-and-events/gallery" exact>
              <SwecsGallery />
            </Route>
            <Route path="/news-and-events/gallery/mba-orentiation-day" exact>
              <MBAOrientationDay2019 />
            </Route>
            <Route path="/news-and-events/gallery/education-matters-counseling" exact>
              <EducationMatterscounseling />
            </Route>
            <Route path="/news-and-events/gallery/gallery-view" exact>
              <InfrastructureManagement />
            </Route>
            <Route path="/news-and-events/gallery/sports-2018" exact>
              <Sports2018 />
            </Route>
            <Route path="/news-and-events/gallery/maithri-2018" exact>
              <Maithri2018 />
            </Route>
            <Route path="/news-and-events/gallery/flashmob-2018" exact>
              <FlashMob2018 />
            </Route>
            <Route path="/news-and-events/gallery/workshop-and-seminar" exact>
              <WorkshopsandSeminars />
            </Route>
            <Route path="/news-and-events/gallery/farewell-2018" exact>
              <Farewell2018 />
            </Route>
            <Route path="/news-and-events/gallery/orientation-program-2016" exact>
              <OrientationProgram2016 />
            </Route>
            <Route path="/news-and-events/gallery/isb-debate" exact>
              <ISBdebate2016 />
            </Route>
            <Route path="/news-and-events/gallery/nss-program" exact>
              <NSSProgramme />
            </Route>
            <Route path="/news-and-events/gallery/jntuh-principal-meet-2019" exact>
              <JntuhPrincipalMeet />
            </Route>
            <Route path="/news-and-events/gallery/vidh-youth-2018" exact>
              <VIDHYOUTH18 />
            </Route>
            <Route path="/news-and-events/news/ideathon-media-coverage" exact>
              <IdeathonMediaCoverage />
            </Route>
            <Route path="/news-and-events/news/fest-srisamvidhan" exact>
              <FestSriSamvidhan />
            </Route>
            <Route path="/news-and-events/news/naac-grade" exact>
              <NAACGrade />
            </Route>
            <Route path="/news-and-events/news/walk-in" exact>
              <WalkinInteriview />
            </Route>
            <Route path="/news-and-events/news/womens-day-celebration-2021" exact>
              <WomensDay2021 />
            </Route>
            <Route path="/news-and-events/news/mou-texas-review" exact>
              <MOUwithTexasReview />
            </Route>
            <Route path="/news-and-events/news/news-coverage-v6" exact>
              <OnlineDataScienceMachine />
            </Route>
            <Route path="/news-and-events/news/iirs-certifcate" exact>
              <IIRSCertifcate />
            </Route>
            <Route path="/news-and-events/news/covid-19-circular" exact>
              <JNTUHCovid19Circular />
            </Route>
            <Route path="/news-and-events/news/she_team-anniversary" exact>
              <SHEteam5thanniversary />
            </Route>
            <Route path="/news-and-events/news/nss-news" exact>
              <NSSNews />
            </Route>
            <Route path="/news-and-events/news/freshers-day" exact>
              <FreshersDay />
            </Route>
            <Route path="/news-and-events/news/national-sports-day-celebratio" exact>
              <NationalSportsDaycelebration />
            </Route>
            <Route path="/news-and-events/news/vidh-youth-2.0" exact>
              <VidhYouth2 />
            </Route>
            <Route path="/news-and-events/news/nss-event" exact>
              <NSSEventSistersforaChange />
            </Route>
            <Route path="/news-and-events/news/graduation-day-2" exact>
              <GraduationDay2019 />
            </Route>
            <Route path="/news-and-events/news/first-year-orientation-day" exact>
              <FirstYearOrientationDay2019 />
            </Route>
            <Route path="/news-and-events/news/nba-certified-news" exact>
              <NBACertified />
            </Route>
            <Route path="/news-and-events/news/pratiyogita2019-news" exact>
              <Pratiyogita2K19News />
            </Route>
            <Route path="/news-and-events/news/qubit-mgit-technical-fest-news" exact>
              <QubitMGITTechnicalfest />
            </Route>
            <Route path="/news-and-events/news/sports-awards-2018-19" exact>
              <SportsAwards201819 />
            </Route>
            <Route path="/news-and-events/news/the-iq-princess-2019-news" exact>
              <TheIQPrincess2019 />
            </Route>
            <Route path="/news-and-events/news/maithri-the-cultural-fest-news" exact>
              <Maithri2K19 />
            </Route>
            <Route path="/news-and-events/news/maithri-2019-news" exact>
              <Maithri2019News />
            </Route>
            <Route path="/news-and-events/news/sri-samvidhan-2019-news" exact>
              <SriSamvidhan2019 />
            </Route>
            <Route path="/news-and-events/news/jbrec-technical-fest-news" exact>
              <JBRECTechnicalFest />
            </Route>
            <Route path="/news-and-events/news/engineering-educators-awards-news" exact>
              <EngineeringEducatorsAwardsNews />
            </Route>
            <Route path="/news-and-events/news/vidh-youth-news" exact>
              <VIDHYOUTH18news />
            </Route>
            <Route path="/news-and-events/news/college-day-news" exact>
              <CollegeDayNews />
            </Route>
            <Route path="/news-and-events/news/swec-placements-2023-24" exact>
              <SWECPlacements />
            </Route>
            <Route path="/news-and-events/news/UGC-autonomous-institute" exact>
              <UGSAutonomousInstitute />
            </Route>
            <Route path="/news-and-events/news/national-youth-festival-2024" exact>
              <NationalYouthFest />
            </Route>
            <Route path="/news-and-events/news/texas-review-2024" exact>
              <TexasReview2024 />
            </Route>
            <Route path="/news-and-events/news/tv9-kab-summit-at-hitex" exact>
              <TV9KABSummitAtHitex />
            </Route>
            <Route path="/news-and-events/news/job-placements" exact>
              <JobPlacememts />
            </Route>
            <Route path="/news-and-events/news/nausainik-camp" exact>
              <NausainikCamp />
            </Route>
            <Route path="/news-and-events/news/nausainik-camp" exact>
              <NausainikCamp />
            </Route>
            <Route path="/news-and-events/news/sports-news" exact>
              <SportsNews />
            </Route>
            <Route path="/news-and-events/news/graduation-day-2023" exact>
              <Graduationday2023 />
            </Route>
            <Route path="/news-and-events/news/it-project-expo-prakalp" exact>
              <Prakalp />
            </Route>
            <Route path="/news-and-events/news/ece-project-expo-2023" exact>
              <ECEProject2023 />
            </Route>

            <Route path="/dept/cse/cse-faculty-list" exact>
              <CSEFaculty />
            </Route>
            <Route path="/dept/it/it-faculty-list" exact>
              <ItFaculty />
            </Route>
            <Route path="/dept/it/eee-faculty-list" exact>
              <EEEFaculty />
            </Route>
            <Route path="/training-and-placements/2021-22" exact>
              <P2122 />
            </Route>
            <Route path="/training-and-placements/2022-23" exact>
              <P2223 />
            </Route>
            <Route path="/training-and-placements/skill-enhancement" exact>
              <SkillEnhancement />
            </Route>
            <Route path="/intellectual-property-rights" exact>
              <IntellectualPropertyRights />
            </Route>
            <Route path="/terms-conditions" exact>
              <TermsConditions />
            </Route>
            <Route path="/extension-activities" exact>
              <ExtensionActivities />
            </Route>
            <Route path="/website-policies" exact>
              <WebsitePolicies />
            </Route>
            <Route path="/copyright-policy" exact>
              <CopyrightPolicy />
            </Route>
            <Route path="/reach-us" exact>
              <ReachUs />
            </Route>
            <Route path="/locations" exact>
              <Location />
            </Route>
            <Route path="/academics" exact>
              <Academics />
            </Route>
            <Route path="/news-and-events/videos" exact>
              <EventsVideos />
            </Route>
            <Route path="/news-and-events/news/gadwal-solar-project" exact>
              <GadwalSolarStreetLightProject />
            </Route>
            <Route path="/news-and-events/events/ece-robotics-workshop-2024" exact>
              <ECERoboticsWorkshop />
            </Route>
            <Route path="/news-and-events/events/coca-cola-beverages" exact>
              <CocaColaBeverages />
            </Route>
            <Route path="/news-and-events/events/yoga-day-2024" exact>
              <InternationalYogaDay2024 />
            </Route>
            <Route path="/news-and-events/events/drug-abuse-day-2024" exact>
              <InternationalDrugAbuseDay2024 />
            </Route>
            <Route path="/news-and-events/news/accenture-ppt-2024" exact>
              <AccenturePptOnJuly31st2024 />
            </Route>
            
          </Switch>
        </Suspense>
      </section>
    </React.Fragment>




  );
}

export default App;
